import { entries, keyBy, noop } from "lodash";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  CommonFilter,
  MiniProduct,
  ProductDetails,
} from "../../@types/entities/product.types";
import { PreviewFilterKeysMaps } from "../../components/filters/filterCmpMap/map";
import { PREVIEW_FILTERS } from "../../components/filters/filtersPreview/FiltersPreview";
import { ProductsGrid } from "../../components/ProductsGrid/ProductsGrid";
import { useFilterActions } from "../../hooks/system/useFilterActions";
import { useViewCaller } from "../../hooks/ui/useWindowCaller";
import { api, baseUrl } from "../../services/api/api";
import { adjustLookalikes } from "../../services/api/products/lookalike/lookalike.v2.api";
import { useAppDispatch, useAppSelector } from "../../store";
import { appActions } from "../../store/app/slice";
import { GigiMessage } from "../home/views/home/GigiPreview";
import { ResultsActions } from "../search/SearchPage";
import { adaptFilterOptions } from "./LookalikePage";
import s from "./LookalikeProducts.module.scss";

export const BaseLookalikeProducts: React.FC<{
  lookalikeImg?: string;
  products: ProductDetails[];
  isLoading?: boolean;
  onClose?: () => void;
  onClickProduct?: (p: MiniProduct) => void;
  filters?: ReactElement;
}> = ({
  lookalikeImg,
  products,
  isLoading: parentIsLoading,
  onClose,
  onClickProduct,
  // filters,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [selectedResults, setSelectedResults] = useState<any>(null);
  const [selectedArea, setSelectedArea] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
    bbox: number[];
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDetecting, setIsDetecting] = useState(false);
  const {
    filtersOptions,
    adaptedStoreActiveFilters,
    updateFilter,
    setStoreFilters,
    isLoadingOptions,
  } = useFilterActions();
  const { searchTerm, user, filters } = useAppSelector(({ app }) => app);
  const [callView] = useViewCaller();
  const [responseEmbeddings, setResponseEmbeddings] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<{
    embeddings: number[];
    segUrl: string;
    originalUrl: string;
    products: ProductDetails[];
  }>({
    embeddings: [],
    segUrl: "",
    originalUrl: "",
    products: [],
  });
  useEffect(() => {
    if (lookalikeImg) {
      const onSelectLookalike = async (path: string) => {
        setIsDetecting(true);
        try {
          const res = await api.calls.post(
            `${baseUrl}/api/v2/similarity/detection`,
            {
              img: path,
            }
          );
          setSelectedResults(res.data.result.results);
          if (res.data.result.results.detections.length == 1) {
            onSetDetection(
              res.data.result.results.detections[0],
              res.data.result.results
            );
          }
        } finally {
          setIsDetecting(false);
        }
      };
      onSelectLookalike(lookalikeImg);
    }
  }, [lookalikeImg]);

  const updateFilterChange = async (key: string, value: string) => {
    const updatedFilters = updateFilter(key, value);
    setStoreFilters(updatedFilters);
    setIsLoading(true);
    try {
      const res = await adjustLookalikes(responseEmbeddings, updatedFilters);
      setData((prev) => ({
        ...prev,
        products: res.items,
      }));
      if (res.features) {
        dispatch(
          appActions.setFilterOptions({
            filterOptions: { isLoading: false, values: res.features },
          })
        );
      }
    } catch (error) {
      console.error("Error fetching similar products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSimilarProducts = async (params: {
    bbox?: number[];
    point?: number[];
    filters?: CommonFilter[];
  }) => {
    setIsLoading(true);
    try {
      const response = await api.calls.post(
        `${baseUrl}/api/v2/similarity/lookalikes`,
        {
          img: lookalikeImg,
          filters: filters,
          ...params,
        }
      );
      setData({
        embeddings: response.data.embedding,
        segUrl: response.data.segUrl,
        originalUrl: response.data.originUrl,
        products: response.data.items,
      });
      setStoreFilters(
        entries(response.data.filters).map(([key, value]) => ({
          key,
          value: [value] as string[],
        }))
      );
      dispatch(
        appActions.setFilterOptions({
          filterOptions: { isLoading: false, values: response.data.features },
        })
      );
    } catch (error) {
      console.error("Error fetching similar products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickPoint = async ({ x, y }: { x: number; y: number }) => {
    await fetchSimilarProducts({ point: [x, y] });
  };

  const onSetDetection = async (d: any, selectedResults: any) => {
    setStoreFilters([]);
    const imageWidth = selectedResults.image_size[0];
    const imageHeight = selectedResults.image_size[1];
    const x = (d.bbox[0] / imageWidth) * 100;
    const y = (d.bbox[1] / imageHeight) * 100;
    const width = ((d.bbox[2] - d.bbox[0]) / imageWidth) * 100;
    const height = ((d.bbox[3] - d.bbox[1]) / imageHeight) * 100;
    setSelectedArea({ x, y, width, height, bbox: d.bbox });
    await fetchSimilarProducts({ bbox: d.bbox });
  };

  return (
    <>
      <div
        ref={scrollRef}
        style={{ position: "relative", height: "100%", overflow: "auto" }}
        className="column flex1 gapLg"
      >
        <div
          style={{
            position: "relative",
            margin: "42px 42px 0 42px",
            backgroundColor: "#f3f3f3",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            {isDetecting && <div className={s.dotGrid} />}
            {selectedResults?.detections?.map((d: any) => (
              <div
                key={`detection-${d.bbox.join("-")}`}
                onClick={() => onSetDetection(d, selectedResults)}
                className={s.detectionIndicator}
                style={{
                  left: `${
                    (((d.bbox[2] - d.bbox[0]) / 2 + d.bbox[0]) /
                      selectedResults.image_size[0]) *
                    100
                  }%`,
                  top: `${
                    (((d.bbox[3] - d.bbox[1]) / 2 + d.bbox[1]) /
                      selectedResults.image_size[1]) *
                    100
                  }%`,
                }}
              />
            ))}
            {selectedArea && (
              <div
                style={{
                  position: "absolute",
                  border: "2px solid rgba(255, 255, 255, 0.9)",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  left: `${selectedArea.x}%`,
                  top: `${selectedArea.y}%`,
                  width: `${selectedArea.width}%`,
                  height: `${selectedArea.height}%`,
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    border: "1px solid rgba(255, 255, 255, 0.7)",
                  }}
                />
                {/* Resize handles */}
                {["nw", "ne", "sw", "se"].map((corner) => (
                  <div
                    key={corner}
                    style={{
                      position: "absolute",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "white",
                      border: "2px solid rgba(255, 255, 255, 0.9)",
                      borderRadius: "30px",
                      ...(corner.includes("n")
                        ? { top: "-5px" }
                        : { bottom: "-5px" }),
                      ...(corner.includes("w")
                        ? { left: "-5px" }
                        : { right: "-5px" }),
                    }}
                  />
                ))}
              </div>
            )}

            <img
              src={lookalikeImg}
              alt="user upload"
              width={"100%"}
              height={"100%"}
              style={{
                maxHeight: "550px",
                objectFit: "contain",
                objectPosition: "top",
              }}
              ref={imgRef}
              onClick={(e) => {
                if (!selectedResults?.image_size) return;

                const rect = imgRef.current?.getBoundingClientRect();
                if (!rect || !imgRef.current) return;

                // Get click coordinates relative to the displayed image
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                // Get the actual displayed image dimensions
                const displayedWidth = imgRef.current.clientWidth;
                const displayedHeight = imgRef.current.clientHeight;

                // Convert to original image coordinates
                const originalWidth = selectedResults.image_size[0];
                const originalHeight = selectedResults.image_size[1];

                const originalX = Math.round(
                  (x / displayedWidth) * originalWidth
                );
                const originalY = Math.round(
                  (y / displayedHeight) * originalHeight
                );

                console.log("Click coordinates:", {
                  displayed: { x, y },
                  original: { x: originalX, y: originalY },
                });

                // onClickPoint({ x: originalX, y: originalY });
                // Clear selected area if needed
                setSelectedArea(null);
              }}
            />
          </div>
        </div>
        <div className="alignCenter gapSm">
          <a
            target="_blank"
            rel="noreferrer"
            href={data.originalUrl}
            className="subTitle"
          >
            Origin
          </a>
          |
          <a
            target="_blank"
            rel="noreferrer"
            href={data.segUrl}
            className="subTitle"
          >
            Seg
          </a>
        </div>
        {selectedArea && (
          <div className="column gapMd">
            <div style={{ height: "100%", overflow: "auto" }}>
              <div
                style={{ borderRadius: "16px", backgroundColor: "white" }}
                className="column paddingMd"
              >
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    textAlign: "center",
                    padding: "16px 0px",
                    background: "white",
                    zIndex: 1,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    overflow: "hidden",
                  }}
                >
                  <div className="column flex1 gapLg alignCenter overflowHidden">
                    <h2 style={{ padding: "0 16px", textAlign: "center" }}>
                      Similar Results
                    </h2>
                    <div
                      style={{
                        alignSelf: "flex-start",
                        overflowX: "hidden",
                        flex: 1,
                        gap: "16px",
                        width: "100%",
                      }}
                      className={"column"}
                    >
                      <GigiMessage text="Adjust the filters if the results are off" />

                      <div
                        className="scrollAuto"
                        style={{
                          width: "100%",
                          overflowX: "auto",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ResultsActions
                          containerClassname={s.resultsActionsContainer}
                          onCLickFilters={() => {
                            callView({
                              id: "filters",
                              data: {
                                onSubmit: noop,
                              },
                            });
                          }}
                          personalizedCount={0}
                          filteredCount={filters.length}
                          filtersProps={{
                            isLoading: isLoadingOptions,
                            options: adaptFilterOptions([
                              ...(filtersOptions?.features.filters ?? []),
                              ...(filtersOptions?.metadata.filters ?? []),
                            ]),
                            activeFilters: keyBy(filters, "key"),
                            onChange: (key: string, value: string) =>
                              updateFilterChange(key, value),
                            previewFilters: filtersOptions.category
                              ? [
                                  "price",
                                  "category",
                                  ...(PreviewFilterKeysMaps[
                                    filtersOptions.category as keyof typeof PreviewFilterKeysMaps
                                  ] ?? PREVIEW_FILTERS),
                                ]
                              : PREVIEW_FILTERS,
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="gapSm alignCenter scrollAuto">
                      {filters && filters}
                    </div> */}
                  </div>
                  {/* {onClose && <div className="flex1" />} */}
                </div>
                <ProductsGrid
                  products={data.products ?? []}
                  isLoading={!!isLoading || isDetecting || !lookalikeImg}
                  fetchNextPage={() => {}}
                  isFetchingNextPage={false}
                  hasNextPage={false}
                  notifyClickProduct={onClickProduct}
                />
              </div>
            </div>
          </div>
        )}
        {!selectedArea && selectedResults && (
          <div className="column gapMd alignCenter">
            <h2 className="flex alignCenter gapSm">
              Select an area
              <div
                style={{ backgroundColor: "#f3f3f3", borderRadius: "50%" }}
                className="paddingMd"
              >
                <div
                  style={{ position: "relative" }}
                  className={s.detectionIndicator}
                />
              </div>
            </h2>
          </div>
        )}
      </div>

      <div
        onClick={() =>
          scrollRef.current?.scroll({ top: 0, behavior: "smooth" })
        }
        style={{
          aspectRatio: "4/5",
        }}
        className={s.summaryLookalike}
        ref={summaryRef}
      >
        <img src={lookalikeImg} alt="summary" />
      </div>
    </>
  );
};
