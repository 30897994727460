import { isEmpty } from "lodash";
import QueryString from "qs";
import { FilterUnit } from "../../../../@types/entities/product.types";
import { api, baseUrl } from "../../api";

export const parseLookalike = async (source: {
  formData?: FormData;
  path?: string;
}) => {
  try {
    if (source.formData) {
      const res = await api.calls.post(
        `${baseUrl}/api/products/lookalike/parse_image`,
        source.formData
      );
      return res.data;
    } else {
      const res = await api.calls.post(
        `${baseUrl}/api/products/lookalike/parse_detection`,
        { path: source.path }
      );
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return { path: null, jobId: null };
  }
};

export const getLookalikejob = async (
  parseId: string,
  filters?: FilterUnit[]
) => {
  const stringified = QueryString.stringify({
    jobId: parseId,
    filters: isEmpty(filters) ? null : filters,
  });
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/v2/similarity/job/${parseId}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getLookalikeFilterOptions = async (type: string) => {
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/products/lookalike/filter_options?type=${type}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getDetectionsBySourceId = async (
  sourceId: string
): Promise<{ source: { path: string; detections: string[] } }> => {
  const res = await api.calls.get(
    `${baseUrl}/api/products/lookalike/detections/${sourceId}`
  );
  return res.data;
};

export const getLookalikeJob = async (detectionUrl: string) => {
  const res = await api.calls.get(
    `${baseUrl}/api/products/lookalike/job?path=${detectionUrl}`
  );
  return res.data;
};
