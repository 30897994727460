import { capitalize } from "lodash";
import { Loader } from "../../../../components/loader/Loader";
import usePreloadImages from "../../../../hooks/ui/usePreloadImages";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { useAppSelector } from "../../../../store";
import { Collection } from "../collections/types";
import { NEW_CATEGORIES, NEW_CATEGORIES_MEN } from "./consts";

const CATEGORIES: Collection[] = [
  {
    title: "Bodycon",
    payload: {
      term: "Bodycon dress",
    },
    thumbnail: getImg("bodycon"),
  },
  {
    title: "Slip",
    payload: {
      term: "slip dress",
    },
    thumbnail: getImg("slip"),
  },
  {
    title: "Beach",
    payload: {
      term: "cover up beach dress",
    },
    thumbnail: getImg("beach"),
  },
  {
    title: "Sweater",
    payload: {
      term: "sweater dress",
      filters: [{ key: "sleeve_length", value: ["long"] }],
    },
    thumbnail: getImg("sweater"),
  },
  {
    title: "Denim",
    payload: {
      term: "Denim fabric dress",
    },
    thumbnail: getImg("denim"),
  },
  {
    title: "Velvet",
    payload: {
      term: "Velvet fabric dress",
    },
    thumbnail: getImg("velvet"),
  },
  {
    title: "Sequin",
    payload: {
      term: "Sequin dress",
    },
    thumbnail: getImg("sequin"),
  },
  {
    title: "Mini Black",
    payload: {
      term: "Mini black dress",
    },
    thumbnail: getImg("mini black"),
  },
  {
    title: "Kaftan",
    payload: {
      term: "kaftan dress",
      filters: [
        { key: "length", value: ["maxi", "midi", "floor"] },
        { key: "sleeve_length", value: ["long"] },
      ],
    },
    thumbnail: getImg("bodycon"),
  },
  {
    title: "Blazer",
    payload: {
      term: "Blazer dress",
    },
    thumbnail: getImg("blazer dress"),
  },
  {
    title: "Plunge",
    payload: {
      term: "deep neckline plunge dress",
    },
    thumbnail: getImg("plunge"),
  },
  {
    title: "Wrap",
    payload: {
      term: "Wrap dress",
    },
    thumbnail: getImg("wrap"),
  },
  {
    title: "Floral",
    payload: {
      term: "Floral dress",
    },
    thumbnail: getImg("floral"),
  },
  {
    title: "Striped",
    payload: {
      term: "Striped dress",
    },
    thumbnail: getImg("striped"),
  },
  {
    title: "Polka Dot",
    payload: {
      term: "polka dot dress",
    },
    thumbnail: getImg("polka dot"),
  },
];

export const CategoriesView = () => {
  const [callWindow] = useViewCaller();
  const user = useAppSelector((s) => s.app.user);
  const categories = user?.gender === "M" ? NEW_CATEGORIES_MEN : NEW_CATEGORIES;
  return (
    <div className="column gapLg">
      {categories.map((c) => (
        <GroupsLayout
          title={c.category}
          groups={c.subs.map((s) => ({
            src: s.thumbnail,
            name: s.name,
          }))}
          onClickGroup={(id: string) => {
            callWindow({
              id: "CollectionView",
              data: {
                title: capitalize(id.replaceAll("_", " ")),
                payload: {
                  term: capitalize(id.replaceAll("_", " ")),
                  tags: [],
                },
              },
            });
          }}
        />
      ))}
    </div>
  );
};

export const GroupsLayout: React.FC<{
  title?: string;
  groups: { name: string; src: string; id?: string }[];
  onClickGroup: (id: string) => void;
}> = ({ groups, title, onClickGroup }) => {
  const [didLoad] = usePreloadImages(groups.map(({ src }) => src));
  if (!didLoad) return <Loader />;
  return (
    <div className="column gapLg ">
      {title && <h3>{title}</h3>}
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          gap: "16px",
        }}
      >
        {groups.map((g) => (
          <div
            className="column gapLg"
            onClick={() => onClickGroup(g.id ?? g.name)}
          >
            <div className="alignCenter">
              <img
                src={g.src}
                style={{
                  borderRadius: "6px",
                  aspectRatio: 3 / 4,
                  objectFit: "cover",
                  minWidth: "130px",
                  width: "130px",
                  objectPosition: "center",
                }}
              />
            </div>
            <p
              style={{ fontSize: "12px", textAlign: "center" }}
              className="justifyCenter"
            >
              {capitalize(g.name.replaceAll("_", " "))}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

function getImg(c: string) {
  return `https://storage.googleapis.com/gigi_pics_public/categories/${c}.jpg`;
}
