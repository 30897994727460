import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { GenderForm } from "./GenderForm";

export const GenderStep: React.FC<OnboardingStep> = (props) => {
  return (
    <BaseOnboardingStep
      Component={GenderForm}
      initialData={props.preloadedData.gender ?? { gender: undefined }}
      title={"To get started, please select your gender"}
      onSkip={props.onSkip}
      onSubmit={(data) => props.onNextStep(data)}
      validate={(data) => !!data.gender}
    />
  );
};
