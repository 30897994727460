import QueryString from "qs";
import { Brand } from "../../../../@types/entities/brand";
import {
  MiniProduct,
  ProductDetails,
} from "../../../../@types/entities/product.types";
import { BasicQuery } from "../../../../@types/query";
import { api, baseUrl } from "../../api";

export const getWizardBrands = async (
  term?: string,
  gender?: string
): Promise<{ items: Brand[] }> => {
  try {
    const { data } = await api.calls.get(
      `${baseUrl}/api/users/wizard/brands?term=${term}&gender=${gender}`
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getWizardSuggestedBrands = async (query: {
  id: string;
  limit?: number;
  skip?: number;
  gender?: string;
}): Promise<{ items: Brand[] }> => {
  const stringified = QueryString.stringify({ ...query });
  try {
    const { data } = await api.calls.get(
      `${baseUrl}/api/users/wizard/brands/suggested?${stringified}`
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getWizardProducts = async ({
  limit = 50,
  skip = 0,
  ...rest
}: BasicQuery): Promise<{ items: ProductDetails[] }> => {
  const query = QueryString.stringify({ ...rest, limit, skip });

  try {
    const res = await api.calls.get(
      `${baseUrl}/api/users/wizard/products?${query}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return { items: [] };
  }
};

export const getProfilePicks = async (
  brandIds: string[]
): Promise<{
  items: { username: string; _id: string; products: MiniProduct[] }[];
}> => {
  const stringified = QueryString.stringify({ brandIds });
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/users/wizard/picks?${stringified}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return { items: [] };
  }
};
