import {
  ChangeEventHandler,
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ViewProps } from "../../@types/commonUi";
import { ProductDetails } from "../../@types/entities/product.types";
import { AppContext } from "../../App";
import { SlidingWindow } from "../../components/slidingWindow/SlidingWindow";
import { useViewCaller } from "../../hooks/ui/useWindowCaller";
import {
  getLookalikejob,
  parseLookalike,
} from "../../services/api/products/lookalike/lookalike.api";
import { BaseLookalikeProducts } from "./LookalikeProducts";

export const LookalikeUploadView: React.FC<
  {
    lookalikeImg: string;
    parseId: string;
  } & ViewProps
> = ({ parseId, lookalikeImg, ...viewProps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<ProductDetails[]>();
  const { socket } = useContext(AppContext);

  useEffect(() => {
    if (!socket.current) return;
    socket.current.on("lookalikeJobDone", async (data: any) => {
      console.log(data.parse_id);
      if (data.parse_id === parseId) {
        const res = await getLookalikejob(data.parse_id);
        setProducts(res.items);
        setIsLoading(false);
      }
    });
    return () => {
      socket.current.off("lookalikeJobDone");
    };
  }, [parseId]);

  return (
    <SlidingWindow {...viewProps}>
      <BaseLookalikeProducts
        products={products ?? []}
        isLoading={isLoading}
        lookalikeImg={lookalikeImg}
        onClose={() => viewProps.onClose()}
      />
    </SlidingWindow>
  );
};

export const UploadWrapper: React.FC<{
  onUploadFile: ChangeEventHandler<HTMLInputElement>;
  children: ReactNode;
  style?: CSSProperties;
}> = ({ onUploadFile, children, style }) => {
  return (
    <div className="column" style={style}>
      <input
        type="file"
        accept="image/*"
        id="fileUploadEmptyView"
        onChange={onUploadFile}
        style={{ visibility: "hidden", maxHeight: 0, maxWidth: 0 }}
      />
      <label htmlFor="fileUploadEmptyView" className="flex1 alignCenter">
        {children}
      </label>
    </div>
  );
};

export const LookalikeWrapper: React.FC<{
  children: ReactNode;
  style?: CSSProperties;
}> = ({ children, style }) => {
  const [callWindow] = useViewCaller();
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files?.length) return;
    handleUpload(event.target.files[0]);
  };

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await parseLookalike({ formData });
    callWindow({
      id: "imgLookalikeView",
      data: {
        parseId: res.parseId,
        lookalikeImg: res.path,
      },
      direction: "right",
    });
  };

  return (
    <UploadWrapper style={style} onUploadFile={handleFileChange}>
      {children}
    </UploadWrapper>
  );
};
