import { useQuery } from "react-query";
import { getSearchTermSuggestions } from "../../../services/api/suggestions";

export const useSearchTermSuggestions = (term: string) => {
  return useQuery({
    queryKey: ["search-term-suggestions", term],
    queryFn: async () => {
      const response = await getSearchTermSuggestions(term);
      return response.items as string[];
    },
    cacheTime: 1000 * 60 * 60,
    enabled: !!term,
  });
};
