import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Skeleton } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { find, first, isEmpty, pick } from "lodash";
import { ReactNode, useMemo, useState } from "react";
import { Brand } from "../../../../@types/entities/brand";
import { Search } from "../../../../components/Input/Search/Search";
import {
  useWizardBrands,
  useWizardSuggestedBrands,
} from "../../../../hooks/query/product/useProductFilters";
import { toggleInList } from "../../../../utils/array";
import commonStyles from "../../common/form.module.scss";
import s from "./BrandSelection.module.scss";

export const BrandContainer: React.FC<{
  children: ReactNode;
  onClick: () => void;
  selected?: boolean;
}> = ({ children, onClick, selected }) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: "relative",
        height: "60px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        minWidth: "100px",
        fontSize: "12px",
        fontWeight: "500",
        padding: "6px",
        border: "thin solid #00000010",
        borderRadius: "6px",
        backgroundColor: "white",
      }}
    >
      {selected && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            transform: `translate(40%,-40%)`,
            backgroundColor: "white",
            border: "thin solid #00000010",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
          }}
        >
          <CloseRoundedIcon fontSize="inherit" />
        </div>
      )}
      {children}
    </div>
  );
};

export const BrandSelection: React.FC<{
  onChange: (data: { brands: Brand[]; gender?: string }) => void;
  data: { brands: Brand[]; gender?: string };
}> = ({ data: { brands: selectedBrands, gender }, onChange }) => {
  const [term, setTerm] = useState("");
  const debouncedValue = useDebounce(term, 400);
  console.log(gender, selectedBrands);

  const {
    data: brands,
    isLoading,
    isError,
  } = useWizardBrands(debouncedValue, gender);
  const { data: suggested, isLoading: isLoadingSuggested } =
    useWizardSuggestedBrands(first(selectedBrands)?._id, gender);

  const displayedBrands = useMemo(() => {
    if (!term && selectedBrands.length) return suggested;

    return brands?.filter((b) => b.count > 10);
  }, [brands, suggested, selectedBrands, term]);

  const toggleBrand = (brand: Brand) => {
    setTerm("");
    onChange({
      brands: toggleInList(
        selectedBrands,
        pick(brand, "_id", "name", "code"),
        "desc"
      ),
      gender,
    });
  };

  const isLoadingBrands =
    (!term && selectedBrands.length && isLoadingSuggested) || isLoading;

  return (
    <div className={s.container}>
      <div className={s.search}>
        <Search
          onChange={(value) => setTerm(value)}
          value={term}
          placeholder="Free People, Norma Kamali, Cult Gaia..."
          theme="light"
          containerClassName={commonStyles.onboardingInput}
        />
      </div>
      {!!selectedBrands.length && (
        <>
          <div className={s.selectedSection}>
            <div className={`${s.selectedList}`}>
              {selectedBrands.map((brand) => (
                <BrandContainer
                  // size="sm"
                  // type="secondary"
                  selected
                  onClick={() => toggleBrand(brand)}
                >
                  {brand.name}
                </BrandContainer>
              ))}
            </div>
          </div>
          <div className={s.separator} />
        </>
      )}
      <div className={s.brandContainer}>
        <div className={s.list}>
          {displayedBrands && !isLoadingBrands && (
            <>
              {displayedBrands
                .filter(
                  (brand) =>
                    !find(selectedBrands, ({ _id }) => _id === brand._id)
                )
                .map((brand) => (
                  <BrandContainer onClick={() => toggleBrand(brand)}>
                    {brand.name}
                  </BrandContainer>
                ))}
            </>
          )}
          {isLoadingBrands &&
            [...new Array(10)].map((_) => (
              <Skeleton
                sx={{ height: "60px", width: "90px", borderRadius: "8px" }}
              />
            ))}
        </div>
        {isEmpty(displayedBrands) && !isLoadingBrands && term.length >= 2 && (
          <div style={{ textAlign: "center", fontSize: "14px" }}>
            No results found...
          </div>
        )}
      </div>
    </div>
  );
};
