import { useQuery } from "react-query";
import { getHomeSuggestions } from "../../../services/api/suggestions";

export const useHomeSugguestions = () => {
  return useQuery({
    queryKey: ["home-suggestions"],
    queryFn: async () => {
      const response = await getHomeSuggestions();
      return response.items as string[];
    },
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 1,
  });
};
