export const NEW_CATEGORIES = [
  {
    category: "Jackets & Coats",
    subs: [
      {
        name: "trench_coat",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/d7/P00920246.jpg",
        key: "trench_coat",
      },
      {
        name: "leather_jacket",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/6f/P00825736.jpg",
        key: "leather_jacket",
      },
      {
        name: "puffer_jacket",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/4/optimized/13865494_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "puffer_jacket",
      },
      {
        name: "parka",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/7/optimized/13737477_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "parka",
      },
      {
        name: "peacoat",
        thumbnail:
          "https://seroya.nyc/cdn/shop/files/Ecommerce_Crop-RS25_LECHEWOOLCOAT_CAMEL_211_ECOMM.jpg?crop=top&height=1792&v=1731524756&width=1280",
        key: "peacoat",
      },
      {
        name: "duffle_coat",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/16/71/15/34/16711534_33964036_1000.jpg",
        key: "duffle_coat",
      },
      {
        name: "bomber_jacket",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/c8/P01010899.jpg",
        key: "bomber_jacket",
      },
      {
        name: "Denim/Suede/Corduroy jacket",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/8c/P00991386.jpg",
        key: "denim_jacket",
      },
      {
        name: "anorak",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/dc/P00923500.jpg",
        key: "anorak",
      },
      {
        name: "blazer",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/28/P00951552.jpg",
        key: "blazer",
      },
      {
        name: "vest",
        thumbnail:
          "https://images.canadagoose.com/image/upload/w_1844,c_scale,f_auto,q_auto/v1721074645/product-image/2333WB_1483_o.png",
        key: "vest",
      },
      {
        name: "cape",
        thumbnail:
          "https://www.mytheresa.com/media/1094/1238/100/75/P00952970.jpg",
        key: "cape",
      },
      {
        name: "wrap_coat",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/0/optimized/13910930_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "wrap_coat",
      },
      {
        name: "car_coat",
        thumbnail:
          "https://www.jcrew.com/s7-img-facade/CE180_WX8011?hei=850&crop=0,0,680,0",
        key: "car_coat",
      },
      {
        name: "quilted_jacket",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/47/P00895471.jpg",
        key: "quilted_jacket",
      },
    ],
  },
  {
    category: "Tops",
    subs: [
      {
        name: "t-shirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/19/P01036621.jpg",
        key: "t-shirt",
      },
      {
        name: "camisole",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/1e/P00582762.jpg",
        key: "camisole",
      },
      {
        name: "blouse",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/bb/P01020163.jpg",
        key: "blouse",
      },
      {
        name: "tank top",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/2c/P00900280.jpg",
        key: "tank_top",
      },
      {
        name: "sweatshirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/8a/P00728490.jpg",
        key: "sweatshirt",
      },
      {
        name: "top",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/4f/P00841569.jpg",
        key: "top",
      },
      {
        name: "oversized",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/99/P00895305.jpg",
        key: "oversized",
      },
      {
        name: "henley",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/f3/P00888069.jpg",
        key: "henley",
      },
      {
        name: "button-down",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/28/P01021502.jpg",
        key: "button-down",
      },
      {
        name: "crop top",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/2e/P00942182.jpg",
        key: "crop_top",
      },
      {
        name: "polo",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/c3/P00962273.jpg",
        key: "polo",
      },
      {
        name: "tunic",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/62/P00752036.jpg",
        key: "tunic",
      },
      {
        name: "halter top",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/1b/P00748888.jpg",
        key: "halter_top",
      },
      {
        name: "corset",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/d8/P00839366.jpg",
        key: "corset",
      },
    ],
  },
  {
    category: "dresses",
    subs: [
      {
        name: "gown",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/22/03/28/19/22032819_52840229_480.jpg",
        key: "gown",
      },
      {
        name: "sundress",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/22/05/64/04/22056404_52217523_480.jpg",
        key: "sundress",
      },
      {
        name: "slip",
        thumbnail:
          "https://media.bergdorfgoodman.com/f_auto,q_auto:low,ar_5:7,c_fill,dpr_2.0,w_429/01/bg_4811908_100649_m",
        key: "slip",
      },
      {
        name: "shirt dress",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/22/05/50/92/22055092_52692852_480.jpg",
        key: "shirt_dress",
      },
      {
        name: "jacket dress",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/7d/P00684896.jpg",
        key: "jacket_dress",
      },
      {
        name: "kaftan",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/23/60/09/72/23600972_54039602_480.jpg",
        key: "kaftan",
      },
      {
        name: "cocktail",
        thumbnail:
          "https://www.net-a-porter.com/variants/images/1647597323463979/in/w764_a3-4_q60.jpg",
        key: "cocktail",
      },
      {
        name: "wrap",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/20/53/49/51/20534951_50537894_480.jpg",
        key: "wrap",
      },
      {
        name: "babydoll",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/23/50/72/89/23507289_53428966_480.jpg",
        key: "babydoll",
      },
    ],
  },
  {
    category: "shoes",
    subs: [
      {
        name: "heel",
        thumbnail:
          "https://images.urbndata.com/is/image/Anthropologie/91399402_060_m?$feed-main$",
        key: "heel",
      },
      {
        name: "boot",
        thumbnail:
          "https://images.urbndata.com/is/image/Anthropologie/78020484_060_b?$feed-main$",
        key: "boot",
      },
      {
        name: "flip_flop",
        thumbnail:
          "https://images.urbndata.com/is/image/Anthropologie/69434926_060_b?$feed-main$",
        key: "flip_flop",
      },
      {
        name: "sandal",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/1/optimized/14056013_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "sandal",
      },
      {
        name: "flat_shoe",
        thumbnail:
          "https://images.urbndata.com/is/image/Anthropologie/98060783_060_m?$feed-main$",
        key: "flat_shoe",
      },
      {
        name: "dress_shoe",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/7/optimized/9200457_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "dress_shoe",
      },
      {
        name: "sneaker",
        thumbnail:
          "https://images.urbndata.com/is/image/Anthropologie/88149687_060_b?$feed-main$",
        key: "sneaker",
      },
      {
        name: "loafer",
        thumbnail:
          "https://images.urbndata.com/is/image/Anthropologie/98287675_060_b?$feed-main$",
        key: "loafer",
      },
      {
        name: "slipper",
        thumbnail:
          "https://images.urbndata.com/is/image/Anthropologie/69055846_060_b?$feed-main$",
        key: "slipper",
      },
      {
        name: "wedge",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/8d/P00999229_d4.jpg",
        key: "wedge",
      },
    ],
  },

  {
    category: "skirt",
    subs: [
      {
        name: "mini_skirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/0a/P00965053.jpg",
        key: "mini_skirt",
      },
      {
        name: "midi_skirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/e7/P00937047.jpg",
        key: "midi_skirt",
      },
      {
        name: "maxi_skirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/72/P00820568.jpg",
        key: "maxi_skirt",
      },
      {
        name: "pencil_skirt",
        thumbnail:
          "https://cdn.accentuate.io/8770273935593/1729728426565/KAY_NAVY_2.jpg?v=1729728426565",
        key: "pencil_skirt",
      },
      {
        name: "a_line_skirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/c0/P00943754.jpg",
        key: "a_line_skirt",
      },
      {
        name: "wrap_skirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/7b/P00912280.jpg",
        key: "wrap_skirt",
      },
      {
        name: "pleated_skirt",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/19/50/53/66/19505366_43865212_480.jpg",
        key: "pleated_skirt",
      },
      {
        name: "denim_skirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/99/P00935995.jpg",
        key: "denim_skirt",
      },
      {
        name: "tiered_skirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/2d/P00954544.jpg",
        key: "tiered_skirt",
      },
      {
        name: "circle_skirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/eb/P00790101.jpg",
        key: "circle_skirt",
      },
    ],
  },

  {
    category: "shorts",
    subs: [
      {
        name: "denim_shorts",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/6d/P00902170.jpg",
        key: "denim_shorts",
      },
      {
        name: "cargo_shorts",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/bc/P00915236.jpg",
        key: "cargo_shorts",
      },
      {
        name: "running_shorts",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/49/P00886898.jpg",
        key: "running_shorts",
      },
      {
        name: "bermuda_shorts",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/d9/P00933178.jpg",
        key: "bermuda_shorts",
      },
      { name: "track_shorts", thumbnail: "", key: "track_shorts" },
      {
        name: "sweat_shorts",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/8a/P00819510.jpg",
        key: "sweat_shorts",
      },
      {
        name: "culottes",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/a9/P00936944.jpg",
        key: "culottes",
      },
      {
        name: "skort",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/16/P00893975.jpg",
        key: "skort",
      },
      {
        name: "high waist",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/c8/P00891398.jpg",
        key: "high_waist",
      },
    ],
  },
  {
    category: "pants",
    subs: [
      {
        name: "jeans",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/95/P00934642.jpg",
        key: "jeans",
      },
      {
        name: "tights",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/6a/P00702990.jpg",
        key: "tights",
      },
      {
        name: "sweatpants",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/f6/P00611024.jpg",
        key: "sweatpants",
      },
      {
        name: "trousers",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/7b/P00882638.jpg",
        key: "trousers",
      },
      {
        name: "cargo",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/ba/P00841070.jpg",
        key: "cargo",
      },
      {
        name: "track pants",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/b7/P00639672.jpg",
        key: "track_pants",
      },
    ],
  },
  {
    category: "Sweaters",
    subs: [
      {
        name: "sweater",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/06/P00581350.jpg",
        key: "sweater",
      },
      {
        name: "cardigan",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/e3/P00889839.jpg",
        key: "cardigan",
      },
      {
        name: "turtleneck",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/e0/P00849383.jpg",
        key: "turtleneck",
      },
    ],
  },
];

export const NEW_CATEGORIES_MEN = [
  {
    category: "Tops",
    subs: [
      {
        name: "t-shirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/18/P00894865.jpg",
        key: "t-shirt",
      },
      {
        name: "tank top",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/d2/P00758254.jpg",
        key: "tank_top",
      },
      {
        name: "oversized",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/a9/P01000072.jpg",
        key: "oversized",
      },
      {
        name: "button-down",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/14279479_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "button-down",
      },
      {
        name: "polo",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/eb/P00943177.jpg",
        key: "polo",
      },
      {
        name: "short-sleeve button-down",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/a2/P00822082.jpg",
        key: "short-sleeve_button-down",
      },
    ],
  },
  {
    category: "Sweaters",
    subs: [
      {
        name: "sweatshirt",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/29/P00734235.jpg",
        key: "sweatshirt",
      },
      {
        name: "sweater",
        thumbnail:
          "https://m.media-amazon.com/images/G/01/Shopbop/p/prod/products/vince/vince5110110495/vince5110110495_p6_2-1._QL100_.jpg",
        key: "sweater",
      },
      {
        name: "cardigan",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/a9/P00826111.jpg",
        key: "cardigan",
      },
      {
        name: "turtleneck",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/fe/P00670480.jpg",
        key: "turtleneck",
      },
    ],
  },
  {
    category: "Jackets & Coats",
    subs: [
      {
        name: "trench_coat",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/d7/P00920246.jpg",
        key: "trench_coat",
      },
      {
        name: "leather_jacket",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/6f/P00825736.jpg",
        key: "leather_jacket",
      },
      {
        name: "puffer_jacket",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/4/optimized/13865494_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "puffer_jacket",
      },
      {
        name: "parka",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/7/optimized/13737477_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "parka",
      },
      {
        name: "peacoat",
        thumbnail:
          "https://seroya.nyc/cdn/shop/files/Ecommerce_Crop-RS25_LECHEWOOLCOAT_CAMEL_211_ECOMM.jpg?crop=top&height=1792&v=1731524756&width=1280",
        key: "peacoat",
      },
      {
        name: "duffle_coat",
        thumbnail:
          "https://cdn-images.farfetch-contents.com/16/71/15/34/16711534_33964036_1000.jpg",
        key: "duffle_coat",
      },
      {
        name: "bomber_jacket",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/c8/P01010899.jpg",
        key: "bomber_jacket",
      },
      {
        name: "denim_jacket, (denim,cordoroy,suede)",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/8c/P00991386.jpg",
        key: "denim_jacket,_(denim,cordoroy,suede)",
      },
      {
        name: "anorak",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/dc/P00923500.jpg",
        key: "anorak",
      },
      {
        name: "blazer",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/28/P00951552.jpg",
        key: "blazer",
      },
      {
        name: "vest",
        thumbnail:
          "https://images.canadagoose.com/image/upload/w_1844,c_scale,f_auto,q_auto/v1721074645/product-image/2333WB_1483_o.png",
        key: "vest",
      },
      {
        name: "cape",
        thumbnail:
          "https://www.mytheresa.com/media/1094/1238/100/75/P00952970.jpg",
        key: "cape",
      },
      {
        name: "wrap_coat",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/0/optimized/13910930_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "wrap_coat",
      },
      {
        name: "car_coat",
        thumbnail:
          "https://www.jcrew.com/s7-img-facade/CE180_WX8011?hei=850&crop=0,0,680,0",
        key: "car_coat",
      },
      {
        name: "quilted_jacket",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/47/P00895471.jpg",
        key: "quilted_jacket",
      },
    ],
  },
  {
    category: "shoes",
    subs: [
      {
        name: "boot",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/17/P00492216.jpg",
        key: "boot",
      },
      {
        name: "athletic_shoe",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/1/optimized/13864854_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "athletic_shoe",
      },
      {
        name: "flip_flop",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/c4/P00945405.jpg",
        key: "flip_flop",
      },
      {
        name: "sandal",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/b8/P00904782.jpg",
        key: "sandal",
      },
      {
        name: "dress_shoe",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/b6/P00964631.jpg",
        key: "dress_shoe",
      },
      {
        name: "sneaker",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/8e/P00645456.jpg",
        key: "sneaker",
      },
      {
        name: "loafer",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/4b/P00945548.jpg",
        key: "loafer",
      },
      {
        name: "slipper",
        thumbnail:
          "https://m.media-amazon.com/images/G/01/Shopbop/p/prod/products/ugggg/ugggg302831071b/ugggg302831071b_1725124140187_2-0._QL100_.jpg",
        key: "slipper",
      },
    ],
  },
  {
    category: "shorts",
    subs: [
      {
        name: "denim_shorts",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/2/optimized/12885672_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "denim_shorts",
      },
      {
        name: "cargo_shorts",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/2/optimized/9883242_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "cargo_shorts",
      },
      {
        name: "running_shorts",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/6/optimized/14035266_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "running_shorts",
      },
      {
        name: "bermuda_shorts",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/5/optimized/13552555_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "bermuda_shorts",
      },
      {
        name: "sweat_shorts",
        thumbnail:
          "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/12959031_fpx.tif?wid=800&qlt=100,0&layer=comp&op_sharpen=0&resMode=bilin&op_usm=0.7,1.0,0.5,0&fmt=jpeg&4msn=.jpg",
        key: "sweat_shorts",
      },
    ],
  },
  {
    category: "pants",
    subs: [
      {
        name: "jeans",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/ae/P00783541.jpg",
        key: "jeans",
      },
      {
        name: "sweatpants",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/b2/P00734282.jpg",
        key: "sweatpants",
      },
      {
        name: "Chinos",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/e7/P00950110.jpg",
        key: "chinos",
      },
      {
        name: "cargo",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/3d/P00752663.jpg",
        key: "cargo",
      },
      {
        name: "dress pants",
        thumbnail:
          "https://img.mytheresa.com/1000/1000/95/jpeg/catalog/product/b2/P00734282.jpg",
        key: "dress_pants",
      },
    ],
  },
];
