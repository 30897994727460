import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Skeleton } from "@mui/material";
import React, { ReactNode } from "react";
import s from "./Pill.module.scss";

export const Pill: React.FC<{
  children: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  isLoading?: boolean;
  size?: "sm" | "md" | "lg" | "xlg";
  type?: "secondary" | "naked" | "ai";
}> = ({ children, size = "md", onClick, isSelected, isLoading, type = "" }) => {
  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        style={{
          minWidth: "80px",
          borderRadius: "50px",
          border: "none",
          height: "30px",
        }}
        className={`${s.pill} ${s[size]}`}
      />
    );
  }

  return (
    <div
      onClick={onClick}
      className={`${s.pill} ${s[size]} ${isSelected ? s.selected : ""} ${
        s[type] ?? ""
      }`}
    >
      {children}
    </div>
  );
};

export const DropdownPill: React.FC<{
  children: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  isLoading?: boolean;
  size?: "sm" | "md" | "lg";
  type?: "secondary" | "naked";
}> = ({ children, ...props }) => {
  return (
    <Pill {...props}>
      <div className="alignCenter gapSm">
        {children}
        <div style={{ fontSize: "14px" }} className="alignCenter">
          <KeyboardArrowDownRoundedIcon fontSize="inherit" />
        </div>
      </div>
    </Pill>
  );
};
