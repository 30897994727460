import { ChevronRightRounded } from "@mui/icons-material";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { List } from "../../../../@types/entities/list";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useLists } from "../../../../hooks/query/lists/useLists";
import { useProductsSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { ReactComponent as AiStyle } from "../../../../styles/icons/ai.svg";
import { GigiSearchSuggestions } from "../../../home/views/home/GigiPreview";
import { ListDetails } from "../../../lists/cmps/listDetails/ListDetails";
import { ListPreview } from "../../../lists/cmps/listPreview/ListPreview";
import s from "../../SearchPage.module.scss";

type Category =
  | "Wedding guest"
  | "Afternoon cocktail"
  | "Family event"
  | "Office party"
  | "Evening gala"
  | "Date night"
  | "Going out"
  | "Summer vacation"
  | "Black-tie";

const categories: Category[] = [
  "Wedding guest",
  "Afternoon cocktail",
  "Family event",
  "Office party",
  "Evening gala",
  "Date night",
  "Going out",
  "Summer vacation",
  "Black-tie",
];

const optionsForCategoryMap: Record<Category, string[]> = {
  "Wedding guest": [
    "Evening wedding",
    "Black maxi",
    "High slit",
    "By the beach",
    "Afternoon",
    "Pastel maxi",
    "casual day",
  ],
  "Afternoon cocktail": [
    "Formal",
    "Light short dresses",
    "Bodycon mini dresses",
    "flowing dresses",
  ],
  "Family event": [],
  "Office party": [],
  "Evening gala": [],
  "Date night": [],
  "Going out": [],
  "Summer vacation": [],
  "Black-tie": [],
};

export const SearchView: React.FC<{
  onSelectOption: (opt: string) => void;
}> = ({ onSelectOption }) => {
  // const { data } = useSearchSections();
  const { data: lists } = useLists();
  const [selectedList, setSelectedList] = useState<List | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [callView] = useViewCaller();

  const onSelectCategory = (cat: Category | null) => {
    setSelectedCategory(cat);
  };
  return (
    <div className={s.searchView}>
      <SearchPills onSearch={onSelectOption} />
      <div className={s.collectionsContainer}>
        <div style={{ fontSize: "14px", fontWeight: 500 }}>
          Curated Collections for you
        </div>
        <div className={s.categories}>
          {/* {[].map((collection) => (
            <div
              className={s.category}
              onClick={() =>
                socketService.emit("windowOpen", "appWindow", {
                  id: "CollectionView",
                  data: {
                    title: collection.title,
                    payload: { ...collection.payload, tags: [] },
                  },
                })
              }
            >
              <img src={collection.src} alt="" />
              <div className={s.titleOverlay}>{collection.title}</div>
            </div>
          ))} */}
        </div>
      </div>

      <SlidingWindow
        isOpen={!!selectedList}
        onClose={() => setSelectedList(null)}
        title={`${selectedList?.title} list`}
        direction="right"
      >
        <ListDetails list={selectedList} initialTab={"suggested"} />
      </SlidingWindow>
    </div>
  );
};

// const stam=()=>{
//   return <>
//   <>
//       {!selectedCategory && (
//         <div className={s.searchView}>
//           {/* <h4>I am Searching for.. </h4> */}
//           <h3 style={{ margin: "15px 0" }}>Recommended By List</h3>
//           <span style={{ fontSize: "13px", color: "#737373" }}>
//             Get tailored suggestions for each list you create
//           </span>
//           {lists?.items.length ? (
//             lists.items.map((list, idx) => (
//               <div style={{ display: "flex", marginTop: "10px" }}>
//                 <ListPreview
//                   key={idx}
//                   list={{
//                     title: `${list.title} list`,
//                     imgs: map(list.savedProducts, "thumbnail"),
//                   }}
//                   onClick={() => setSelectedList(list)}
//                 />
//               </div>
//             ))
//           ) : (
//             <span>Create lists and get tailored suggestions for each list</span>
//           )}

//           <h3 style={{ margin: "25px 0 10px 0" }}>Shop By Occasion</h3>
//           {categories.map((option) => (
//             <CateogryListItem onClick={() => onSelectCategory(option)}>
//               {option}
//             </CateogryListItem>
//           ))}

//           <h3 style={{ margin: " 15px 0 " }}>Shop By Style</h3>
//           {[
//             "Maxi dresses",
//             "Mini dresses",
//             "Bodycon dresses",
//             "Flowing dresses",
//             "Sundresses",
//             "Dramatic dresses",
//             "Romantic dresses",
//             "Contemporary dresses",
//           ].map((option) => (
//             <CateogryListItem onClick={() => onSelectOption(option)}>
//               {option}
//             </CateogryListItem>
//           ))}
//         </div>
//       )}
//       </>
//       <>
//       {selectedCategory && (
//         <>
//           <div className="alignCenter">
//             <div
//               className="paddingMd alignCenter"
//               onClick={() => onSelectCategory(null)}
//             >
//               <ChevronLeftRounded />
//             </div>
//             <h3>{selectedCategory} dresses</h3>
//           </div>
//           <div
//             style={{
//               padding: "15px 0",
//               textDecoration: "underline",
//               fontWeight: 500,
//             }}
//           >
//             Shop all {selectedCategory.toLowerCase()}
//           </div>
//           <div className="column flex1 gapLg">
//             {optionsForCategoryMap[selectedCategory!].map((option, idx) => (
//               <div style={{ display: "flex", marginTop: "10px" }}>
//                 <OptionPreviewList
//                   optionSearchTerm={`${option} ${selectedCategory} dress`}
//                   option={option}
//                   onClickOption={(term) => onSelectOption(term)}
//                 />
//               </div>
//             ))}
//           </div>
//         </>
//       )}</>
//   </>
// }

export const SearchPills: React.FC<{ onSearch: (s: string) => void }> = ({
  onSearch,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.classList.add(s.move);
  }, []);
  return (
    <div className="column gapXlg">
      <div className={s.searchContainer}>
        <h3 className={`gapMd justifyCenter `}>
          <AiStyle width={24} height={24} />
          Recommended By Gigi
        </h3>
        <div className={s.searchPillsContainer}>
          <div
            onTouchStart={() => ref.current?.classList.remove(s.move)}
            className={`${s.searchPills}`}
            ref={ref}
          >
            <GigiSearchSuggestions onSearch={onSearch} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CateogryListItem: React.FC<{
  children: ReactNode;
  arrow?: boolean;
  onClick: () => void;
}> = ({ children, arrow, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        padding: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>{children}</div>

      <ChevronRightRounded />
    </div>
  );
};

const OptionPreviewList: React.FC<{
  option: string;
  optionSearchTerm: string;
  onClickOption: (option: string) => void;
}> = ({ option, onClickOption, optionSearchTerm }) => {
  const { data, isLoading } = useProductsSearch({
    term: optionSearchTerm,
    limit: 5,
  });

  return (
    <ListPreview
      list={{ title: option, imgs: data?.map((p) => p.imgUrls[0]) ?? [] }}
      onClick={() => onClickOption(optionSearchTerm)}
    />
  );
};
