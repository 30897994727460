import { get } from "lodash";
import { Pill } from "../../../../components/pill/Pill";

const values = [
  {
    title: "casual wear",
    description: "Running errands, coffee date.",
    key: "casual",
  },
  {
    title: "event wear",
    description: "Weddings, formal events.",
    key: "event",
  },
  {
    title: "outerwear",
    description: "date night vibes, girls night out in town.",
    key: "outerwear",
  },
  {
    title: "athleisure",
    description: "",
    key: "athleisure",
  },
];

export const ShoppingPreferencesInfo: React.FC<{
  data: any;
  onChange: (data: any) => void;
}> = ({ data = {}, onChange }) => {
  const onSelectRange = (type: string, range: number) => {
    onChange({ ...data, [type]: range });
  };

  return (
    <div className="column gapXlg flex1">
      {values.map((v) => (
        <div className="column gapLg overflowHidden">
          <div className="gapMd column">
            <h4>{v.title}</h4>
            <span
              style={{
                fontSize: "12px",
                fontWeight: 300,
                fontStyle: "italic",
                color: "#737373",
              }}
            >
              {v.description}
            </span>
          </div>
          <div className="alignCenter gapMd overflowAuto">
            <Pill
              size="md"
              isSelected={get(data, v.key, 0) === 1}
              onClick={() => onSelectRange(v.key, 1)}
            >
              $
            </Pill>
            <Pill
              size="md"
              isSelected={get(data, v.key, 0) === 2}
              onClick={() => onSelectRange(v.key, 2)}
            >
              $$
            </Pill>
            <Pill
              size="md"
              isSelected={get(data, v.key, 0) === 3}
              onClick={() => onSelectRange(v.key, 3)}
            >
              $$$
            </Pill>
            <Pill
              size="md"
              isSelected={get(data, v.key, 0) === 4}
              onClick={() => onSelectRange(v.key, 4)}
            >
              $$$$
            </Pill>
          </div>
        </div>
      ))}
    </div>
  );
};
