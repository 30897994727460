import { LinearProgress, LinearProgressProps, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { capitalize, values } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { Brand } from "../../@types/entities/brand";
import { User } from "../../@types/entities/user";
import { AppContext } from "../../App";
import { Password } from "../../components/Input/Password/Password";
import { AppTextField } from "../../components/Input/TextField/TextField";
import { Back } from "../../components/back/Back";
import { Button } from "../../components/button/Button";
import { Image } from "../../components/image/Img";
import {
  DesktopMedia,
  TouchScreenMedia,
} from "../../components/media/MediaQueries";
import { useOnboardStyles } from "../../hooks/query/onboard/useOnboardStyles";
import { useProfilePicks } from "../../hooks/query/users/useProfilePicks";
import { useEditUserProfile } from "../../hooks/query/users/useUsers";
import { usePageStyles } from "../../hooks/ui/usePageStyles";
import { sendForgotPassword } from "../../services/api/users/users.api";
import { GradientBackground } from "../home/HomePage";
import s from "./OnboardPage.module.scss";
import formStyles from "./common/form.module.scss";
import { BodyStep } from "./steps/bodyStep/BodyStep";
import { BrandSelectionStep } from "./steps/brandSelection/BrandSelectionStep";
import { ColorsStep } from "./steps/colorStep/ColorStep";
import { GenderStep } from "./steps/genderStep/GenderStep";
import { IntroStep } from "./steps/introStep/infoStep";
import { ProfessionStep } from "./steps/professionStep/ProfessionStep";
import { ShoppingPreferencesStep } from "./steps/shoppingPrefrencesStep/ShoppingPreferencesStep";
import { SignupStep } from "./steps/signupStep/SignupStep";
import { StyleSelectionStep } from "./steps/styleSelection/StyleSelection";
import { UserInfoStep } from "./steps/userInfoStep/UserInforFormStep";
import { OnboardingStep } from "./types";

const MaleSteps = [
  GenderStep,
  // IntroStep,
  UserInfoStep,
  ProfessionStep,
  ShoppingPreferencesStep,
  BrandSelectionStep,
  // BodyStep,
  ColorsStep,
  // StyleSelectionStep,
  SignupStep,
];

const femaleSteps = [
  GenderStep,
  IntroStep,
  UserInfoStep,
  ProfessionStep,
  ShoppingPreferencesStep,
  BrandSelectionStep,
  BodyStep,
  ColorsStep,
  StyleSelectionStep,
  SignupStep,
];

export const OnboardPage: React.FC = () => {
  const [state, setState] = useState({ steps: femaleSteps });
  const [step, setStep] = useState(0);
  const [form, setForm] = useState<Record<string, any>>(InitialForm);
  const [error, setError] = useState("");
  const styles = usePageStyles();
  const preloadDataForStylesStep = useOnboardStyles({});
  const { isMobile } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const { mutateAsync: submitProfile, isLoading: isSubmitting } =
    useEditUserProfile();
  const navigate = useNavigate();

  const onSubmitStep = async (partialForm: any) => {
    if (partialForm.gender === "M") {
      setState({ steps: MaleSteps });
    }
    if (step < state.steps.length - 1) {
      setForm((form) => ({ ...form, ...partialForm }));
      setStep((step) => step + 1);
    } else {
      await onSubmitForm({ ...form, ...partialForm });
    }
  };

  useEffect(() => {
    try {
      const initStep = +(searchParams.get("step") ?? "");
      if (initStep > 0 && initStep <= state.steps.length - 1) {
        setStep(initStep);
      }
    } catch (error) {}
  }, []);

  const onSubmitForm = async (form: any) => {
    try {
      await submitProfile({
        ...form,
      } as Omit<User, "brands"> & { password: string });
      navigate(`/home?${searchParams.toString()}`);
    } catch (error: any) {
      setError(
        error!.response.data.msg ??
          "An unknown error has occurred. Please close the app and try again "
      );
    }
  };

  const isLastStep = step === formValidator.length - 1;

  return (
    <>
      <DesktopMedia>
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={s.desktopMsg}>
            <h2>We suggest moving to a mobile browser {":)"}</h2>
            <div>
              As an AI startup, we have a mobile-first approach.
              <br /> a desktop version will be released soon.{" "}
            </div>
          </div>
          <iframe
            id="forceMobile"
            style={{
              width: "535px",
              height: "900px",
              outline: "none",
              border: "thin solid #f5f5f5",
              borderRadius: "15px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            src="https://shopper-h65l.onrender.com/onboard"
          />
        </div>
      </DesktopMedia>
      <TouchScreenMedia>
        <GradientBackground>
          <div className={s.page} style={styles}>
            <div
              style={{
                marginBottom: "8px",
                paddingInlineStart: "16px",
                minHeight: "24px",
              }}
              className="alignCenter flex"
            >
              {!!step && <Back onClick={() => setStep((s) => s - 1)} />}

              <div className={s.progressContainer}>
                <ProgressBar
                  variant="determinate"
                  value={(step / state.steps.length) * 100}
                />
              </div>
            </div>
            <div className={s.contentContainer}>
              {step <= state.steps.length - 1 && (
                <StepResolver
                  step={step}
                  state={state}
                  onNextStep={async (data) => {
                    setError("");
                    await onSubmitStep(data);
                  }}
                  error={error}
                  preloadedData={form}
                />
              )}
            </div>
          </div>
        </GradientBackground>
      </TouchScreenMedia>
    </>
  );
};

const StepResolver: React.FC<OnboardingStep & { step: number; state: any }> = ({
  step,
  state,
  ...restProps
}) => {
  if (step > state.steps.length - 1) return null;
  const StepComponent = state.steps[step];
  return <StepComponent {...restProps} />;
};

const Welcome = () => {
  return (
    <div className={s.step}>
      <div></div>
      <div className={s.explanationContainer}>
        <div className={`${s.explanationTitle} spaceDownLg`}>
          A delightful fashion experience <br />
          begins now
        </div>
        <div className={`${s.explanationSubtitle}`}>
          We turn your fashion ideas into reality. <br /> With your own AI
          personal shopper, enjoy <br /> a shopping experience that saves time
          and money.
        </div>
      </div>
    </div>
  );
};

export const PersonalizedExplanation = () => {
  return (
    <div className={s.step}>
      <div></div>
      <div className={s.explanationContainer}>
        <span className={s.explanationTitle}>
          A truly personalized experience
        </span>
        <p className={s.explanationSubtitle}>
          Your personal shopper learns what you love, and adapts to ensure each
          result is a perfect match for your taste and lifestyle.
        </p>
      </div>
    </div>
  );
};

export const ChatIntro = () => {
  return (
    <div className={s.step}>
      <div className={s.chatsImgContainer}>
        <div className="justifyEnd"></div>
      </div>
      <div className={s.explanationContainer}>
        <span className={s.explanationTitle}>Finally, someone to talk to</span>
        <p className={s.explanationSubtitle}>
          From the first chat, your personal shopper understands your fashion
          desires - helping you transform an idea in your mind into a product
          you'll love.
        </p>
      </div>
    </div>
  );
};

export const PresetProfileList: React.FC<{
  profiles?: User[];
  onSelectProfile: (preset: string | null) => void;
  selectedProfileId: string | null;
  brandIds: string[];
  enabled: boolean;
}> = ({
  onSelectProfile,
  selectedProfileId: selectedProfile,
  brandIds,
  enabled,
}) => {
  const { data, isLoading } = useProfilePicks(brandIds, enabled);
  if (isLoading) {
    return (
      <>
        <Skeleton
          style={{
            display: "flex",
            borderRadius: "16px",
            padding: "100px",
            flex: 1,
          }}
        />
        <Skeleton
          style={{
            display: "flex",
            borderRadius: "16px",
            padding: "100px",
            flex: 1,
          }}
        />
        <Skeleton
          style={{
            display: "flex",
            borderRadius: "16px",
            padding: "100px",
            flex: 1,
          }}
        />
      </>
    );
  }

  return (
    <div className={s.presetProfiles}>
      {data?.map((profile) => (
        <div
          className={`${s.profile} ${
            profile._id === selectedProfile ? s.selected : ""
          }`}
          onClick={() => onSelectProfile(profile._id!)}
        >
          <div className={s.profileHeader}>
            {/* <Avatar initials="AA" /> */}
            <div className={s.profileHeaderInfo}>
              <span className={s.profileName}>
                {capitalize(profile.username)}
              </span>
            </div>
          </div>
          <div className={s.profilePicksContainer}>
            <span>Vibe</span>
            <div className={s.picksList}>
              {profile.products.map((p) => (
                <Image
                  fallback={
                    p.parsing_path ?? p.feature_parse_path ?? p.seg_path
                  }
                  src={p.imgUrls[0]}
                  className={s.pickImg}
                  alt="pick"
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export interface SignInDetails {
  username: string;
  password: string;
}
export const UserSignIn: React.FC<{
  form: SignInDetails;
  onChange: (form: SignInDetails) => void;
  isLogin?: boolean;
  error?: string;
}> = ({ form, onChange, isLogin, error }) => {
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  if (isForgotPassword) return <ForgotPassword initialEmail={form.username} />;
  return (
    <div className="column gapXlg flex1">
      <div className="column gapLg">
        <div className="column gapSm">
          <span className="inputLabel">Email</span>
          <AppTextField
            type="email"
            value={form.username}
            containerClassName={formStyles.onboardingInput}
            onChange={(ev) => onChange({ ...form, username: ev.target.value })}
            placeholder="email"
          />
        </div>
        <div className="column gapSm">
          <span className="inputLabel">Password</span>
          <Password
            placeholder="Password"
            value={form.password}
            containerClassName={formStyles.onboardingInput}
            // type="password"
            onChange={(value) => onChange({ ...form, password: value })}
          />
        </div>
        {error && <div style={{ color: "red", fontSize: "14px" }}>{error}</div>}
        {isLogin && (
          <div
            style={{ fontSize: "12px", textAlign: "center" }}
            className="underline"
            onClick={() => setIsForgotPassword(true)}
          >
            Forgot password
          </div>
        )}
      </div>
      {isLogin && (
        <div style={{ marginTop: "32px", textAlign: "center" }}>
          Don't have an account?{" "}
          <Link className="underline" to="/onboard">
            Sign up
          </Link>
        </div>
      )}
    </div>
  );
};
export const ProgressBar = styled(LinearProgress)<LinearProgressProps>({
  "&.MuiLinearProgress-root": {
    height: "8px",
    margin: "0px 20px",
    borderRadius: "15px",
    backgroundColor: "#e8e8e8",
  },
  ".MuiLinearProgress-bar1Determinate": {
    height: "8px",
    background:
      "linear-gradient(90deg, rgba(253,252,255,0.7665660014005602) 0%, rgba(183,221,253,1) 13%, rgba(195,189,253,1) 58%, rgba(220,169,245,1) 79%)",
  },
});

const ForgotPassword: React.FC<{
  initialEmail: string;
}> = ({ initialEmail }) => {
  const [email, setEmail] = useState(initialEmail);
  const [isSent, setIsSent] = useState(false);
  const {
    mutateAsync: sendVerification,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: async (email: string) => {
      await sendForgotPassword(email);
    },
  });

  const onForgotPassword = async () => {
    try {
      await sendVerification(email);
      setIsSent(true);
    } catch (error) {}
  };

  return (
    <div className={s.step}>
      <div className="column gapXlg">
        {!isSent ? (
          <>
            <div className={s.explanationTitle}>Reset your password</div>
            <AppTextField
              type="email"
              value={email}
              placeholder="Your registered email"
              onChange={(ev) => setEmail(ev.target.value)}
            />

            <Button isLoading={isLoading} onClick={() => onForgotPassword()}>
              Send reset email
            </Button>

            {isError && <div className="error">Please enter a valid email</div>}
          </>
        ) : (
          <div className={s.explanationTitle}>Password sent to {email}</div>
        )}
      </div>
    </div>
  );
};

// TODO: LATER, INTERFACE AND STUFF FOR SELF VALIFATION, PROBABLY A CLASS TO IMPLEMENT AN INTERFACE. NOT NOW, I HAVE A HUGE HEADACHE

const formValidator: Array<(form: UserProfileBuilderForm) => boolean> = [
  // () => true,
  (form) => values(form.profile).every((value) => !!value),
  // () => true,
  (form) => form.brands.length >= 3,
  // () => true,
  (form) => form.presetProfileId !== "",
  (form) => !!form.signInDetails.username && !!form.signInDetails.password,
  // 2: (form) => form.casual.length > 3,
  // 3: (form) => form.formal.length > 3,
  // 4: (form) => form.night.length > 3,
];

export interface UserProfileBuilderForm {
  profile: {
    age: string;
    // location: string;
    // job: string;
    name: string;
  };
  brands: Brand[];
  casual?: string[];
  formal?: string[];
  night?: string[];
  presetProfileId: string | null;
  signInDetails: {
    username: string;
    password: string;
  };
}
const InitialForm = {
  body: {},
  gender: "W",
  brands: [],
  colors: {},
  info: {},
  pricing: {},
  profession: {},
  profile: {},
  style: {},
  signInDetails: {},
};
