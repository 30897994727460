import { OnboardingStep } from "../../types";
import BaseOnboardingStep from "../baseStep/BaseStep";
import { BrandSelection } from "./BrandSelection";

export const BrandSelectionStep: React.FC<OnboardingStep> = ({
  onSkip,
  onNextStep,
  preloadedData,
}) => {
  return (
    <BaseOnboardingStep
      Component={BrandSelection}
      validate={(data) => (data?.brands.length ?? 0) > 5}
      initialData={{
        gender: preloadedData.gender,
        brands: preloadedData?.brands ?? [],
      }}
      title={"Select at least 5 brands you love to shop from"}
      onSkip={onSkip}
      onSubmit={(data) => onNextStep({ brands: data.brands })}
    />
  );
};
