import { CommonFilter } from "../../../../@types/entities/product.types";
import { api, baseUrl } from "../../api";

export async function getDetections(imgPath: string) {
  const res = await api.calls.post(`${baseUrl}/api/v2/similarity/detection`, {
    img: imgPath,
  });
  return res.data;
}

export async function getLookalikes(imgPath: string, bbox: number[]) {
  const res = await api.calls.get(`${baseUrl}/api/v2/similarity/lookalikes/`, {
    params: {
      img: imgPath,
      bbox: bbox,
    },
  });
  return res.data;
}

export async function adjustLookalikes(
  embedding: number[],
  filters: CommonFilter[]
) {
  const res = await api.calls.post(
    `${baseUrl}/api/v2/similarity/lookalikes/adjust`,
    {
      embedding: embedding,
      filters: filters,
    }
  );
  return res.data;
}
