import { ColorValues } from "./colorValues/ColorValues";
import { LabelValues } from "./labelValues/LabelValues";
import { PriceRangeValues } from "./PriceRangeValues";

export const FilterCmpMap = {
  price: PriceRangeValues,
  main_color: ColorValues,
  dominant_color: ColorValues,
  secondary_color: ColorValues,
  main_color_specific: ColorValues,
  sleeve_length: LabelValues,
  category: LabelValues,
  skirt_group: LabelValues,
  neckline_group: LabelValues,
  pattern_style: LabelValues,
  length_group: LabelValues,
  strap_group: LabelValues,
  length: LabelValues,
  pattern: LabelValues,
  silhouette: LabelValues,
  sleeve: LabelValues,
  back: LabelValues,
  bodice: LabelValues,
  hemline: LabelValues,
  lining: LabelValues,
  seasonality: LabelValues,
  transparency: LabelValues,
  neckline: LabelValues,
  fit: LabelValues,
  strap: LabelValues,
  skirt: LabelValues,
};

export const PreviewFilterKeysMaps = {
  top: ["dominant_color", "length", "neckline", "pattern", "sleeve_length"],
  skirt: ["dominant_color", "length", "material", "pattern", "embellishments"],
  pants: ["dominant_color", "length", "fit", "material", "pattern"],
  shorts: ["dominant_color", "length", "pattern", "material"],
  dress: ["dominant_color", "length", "neckline", "pattern", "sleeve_length"],
  coat: ["dominant_color", "length", "material", "pattern"],
  shoes: ["dominant_color", "material", "heel_height", "Toe shape"],
};
