import { useQuery } from "react-query";
import { getSearchPageSuggestions } from "../../../services/api/suggestions";

export const useSearchPageSuggestions = () => {
  return useQuery({
    queryKey: ["search-page-suggestions"],
    queryFn: async () => {
      const response = await getSearchPageSuggestions();
      return response.items as string[];
    },
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 5,
  });
};
