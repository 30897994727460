import { Pill } from "../../../../components/pill/Pill";
import s from "../../common/form.module.scss";
export const ProfessionInfo: React.FC<{
  data: any;
  onChange: (data: any) => void;
}> = ({ data = {}, onChange }) => {
  const onChangeForm = (key: string, value: string) => {
    onChange({ ...data, [key]: value });
  };
  return (
    <>
      <div className={s.form}>
        <div className={s.field}>
          <h4>Occupation field</h4>
          <div className="alignCenter scrollAuto gapMd">
            {[
              ...[
                "Student",
                "Fashion",
                "Marketing",
                "Retail",
                "Hospitality",
                "Technology",
                "Law",
                "Healthcare",
                "Wellness & Sport",
                "Finance",
                "Design",
                "Education",
                "Real estate",
                "Entertainment & Media",
              ].sort(),
              "Other...",
            ].map((o) => (
              <Pill
                size="md"
                isSelected={o === data.occupation}
                onClick={() => onChangeForm("occupation", o)}
              >
                {o}
              </Pill>
            ))}
          </div>
        </div>
        <div className={s.field}>
          <h4>Typical dress code</h4>
          <div className="scrollAuto gapMd alignCenter">
            {[
              "Business formal",
              "Business casual",
              "Smart casual",
              "Casual",
              "Athletic",
              "Uniformed",
            ].map((o) => (
              <Pill
                size="md"
                isSelected={o === data.dressCode}
                onClick={() => onChangeForm("dressCode", o)}
              >
                {o}
              </Pill>
            ))}
          </div>
        </div>
        <div className={s.field}>
          <h4>Work setup</h4>
          <div className="scrollAuto gapMd alignCenter">
            {["On site", "Remote", "Hybrid"].map((o) => (
              <Pill
                size="md"
                isSelected={o === data.setup}
                onClick={() => onChangeForm("setup", o)}
              >
                {o}
              </Pill>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
