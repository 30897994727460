import { Pill } from "../../../../components/pill/Pill";

export const GenderForm: React.FC<{
  onChange: (data: any) => void;
  data: any;
}> = ({ onChange, data }) => {
  return (
    <div className="column">
      <div style={{ padding: "30% 10% 0 10%" }} className="column gapLg flex1">
        <Pill
          size="lg"
          isSelected={data.gender === "M"}
          onClick={() => onChange({ gender: "M" })}
        >
          Male
        </Pill>
        <Pill
          size="lg"
          isSelected={data.gender === "W"}
          onClick={() => onChange({ gender: "W" })}
        >
          Female
        </Pill>
      </div>
    </div>
  );
};
