import { api, baseUrl } from "./api";

export interface Suggestion {
  id: string;
  title: string;
  type: "product" | "brand" | "category";
  imageUrl?: string;
}

export interface SuggestionResponse {
  items: string[];
}

export const getHomeSuggestions = async (): Promise<SuggestionResponse> => {
  const response = await api.calls.get(`${baseUrl}/api/v2/suggestions/home`);
  return response.data;
};

export const getSearchTermSuggestions = async (
  query: string
): Promise<SuggestionResponse> => {
  const response = await api.calls.get(
    `${baseUrl}/api/v2/suggestions/search_term`,
    {
      params: {
        query,
      },
    }
  );
  return response.data;
};

export const getSearchPageSuggestions =
  async (): Promise<SuggestionResponse> => {
    const response = await api.calls.get(
      `${baseUrl}/api/v2/suggestions/search_page`
    );
    return response.data;
  };
