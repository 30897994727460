import { useState } from "react";
import { useParams } from "react-router";
import { Loader } from "../../../components/loader/Loader";
import { SlidingWindow } from "../../../components/slidingWindow/SlidingWindow";
import { useSourceOutfitDetections } from "../../../hooks/query/detections/useSourceOutfitDetections";
import { BaseLookalikeProducts } from "../LookalikeProducts";

export const DetectionPage = () => {
  const { sourceId } = useParams();
  const { data, isLoading, isError } = useSourceOutfitDetections(sourceId);
  const [detection, setDetection] = useState<string | null>();
  const [isLoadingDetection, setIsLoadingDetection] = useState(false);
  const [currentSimilarityPath, setCurrentSimilarityPath] = useState<
    string | null
  >(null);
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>ERROR</div>;
  }

  const onClickDetection = async (detectionUrl: string) => {
    setCurrentSimilarityPath(detectionUrl);
    // setIsLoadingDetection(true);
    // const parsingJob = await getLookalikeJob(detectionUrl);
    // let parseId = parsingJob?._id;
    // if (!parseId) {
    //   const data = await parseLookalike({ path: detectionUrl });
    //   parseId = data.parseId;
    // }
    // setIsLoadingDetection(false);
    // setDetection(parseId);
  };
  return (
    <div className="flex1 column gapLg scrollAuto">
      <div className="alignCenter justifyCenter flex">
        <video
          className="flex1"
          src={data?.source?.path}
          controls
          style={{ maxWidth: "200px" }}
        />
      </div>

      <div
        style={{ flexWrap: "wrap" }}
        className="alignCenter justifyCenter gapMd"
      >
        {data?.source.detections.map((d) => (
          <img
            style={{
              maxWidth: "100px",
              aspectRatio: "3/5",
              objectFit: "cover",
              borderRadius: "16px",
            }}
            onClick={() => onClickDetection(d)}
            alt="detection"
            src={d}
          />
        ))}
      </div>

      <SlidingWindow
        isOpen={!!isLoadingDetection || !!currentSimilarityPath}
        onClose={() => setCurrentSimilarityPath(null)}
        keepMounted={false}
      >
        <BaseLookalikeProducts
          products={[]}
          // isLoading={isLoading}

          lookalikeImg={currentSimilarityPath ?? ""}
          // filters={
          //   filterOptions &&
          //   filters && (
          //     <FiltersPreview
          //       options={adaptFilterOptions(filterOptions)}
          //       activeFilters={keyBy(filters, "key")}
          //       onChange={(key, value) =>
          //         setFilters((prev) => updateFilter(key, value, prev))
          //       }
          //       previewFilters={PREVIEW_LOOKALIKE_FILTERS}
          //     />
          //   )
          // }
          onClickProduct={(p) => {
            window.open(p.vendors[0].url, "_blank");
          }}
        />
      </SlidingWindow>
    </div>
  );
};
